// import 'babel-polyfill';
import Head from "next/head";
import dynamic from "next/dynamic";
import Header from "@/Header";
import Footer from "@/Footer";
import Maintain from "@/Maintain";
import { Spin } from "antd";
import "../../config/Global";
import "../../config/Globalfun";
import React, { useState, useEffect } from "react";
import SelfExclusionModal from "../SelfExclusionModal";
import Router from "next/router";
import seoStatic from "$DATA/seo/static";
import SmarticoMaintenance from "@/Games/SmarticoMaintenance";

const DynamicRestrictAccess = dynamic(import("@/RestrictAccess/ipError"), {
    loading: () => (
        <Spin
            style={{ position: "absolute", top: "30%", left: 0, right: 0 }}
            spinning={true}
            size="large"
            tip="กำลังโหลด กรุณารอสักครู่..." //加载中，请稍后...
        />
    ),
    ssr: true,
});

function MainComponent(props) {
    const [isLogin, setIsLogin] = useState(false);
    /* 
        HasLogged => 設置 isWalletSetupComplete 來判斷 showModal 已設置完成，避免開啟遊戲確認餘額時 global.showDialog 為空
        NotLogged => isWalletSetupComplete 直接設為 true
    */
    const [isWalletSetupComplete, setIsWalletSetupComplete] = useState(false);

    const commonParams = {
        headerHeightLock: props.headerHeightLock,
        setLockHeader: props.setLockHeader,
        setLoginStatus: props.setLoginStatus,
        setCircleHasUnRead: props.setCircleHasUnRead,
        setUsercenterCircle: props.setUsercenterCircle,
        setUserCenterMoney: props.setUserCenterMoney,
        setUserCenterMemberInfo: props.setUserCenterMemberInfo,
        setOpenGame: props.setOpenGame,
        getPromotionList: props.getPromotionList,
        definedHeaderNode: props.definedHeaderNode,
    };
    // global.HttpStatus = 4;
    const globalStatusKey = global.HttpStatus || props.status || 1;

    switch (globalStatusKey) {
        case 5:
            return <React.Fragment>{props.children}</React.Fragment>;
        case 1:
            return (
                <div
                    className={`tlc-container-wrapper ${
                        props.wrapperClassName ? props.wrapperClassName : ""
                    } ${
                        props.headerHeightLock
                            ? "_" + props.headerHeightLock
                            : ""
                    }`}
                >
                    <Header
                        key="main-header"
                        {...commonParams}
                        setIsLogin={(val) => {
                            setIsLogin(val);
                        }}
                    />
                    {props.children}
                    <Footer key="main-footer" isLogin={isLogin} isHomePage={props.isHomePage} />
                </div>
            );
        case 2:
            return (
                <div
                    className={`tlc-container-wrapper ${
                        props.wrapperClassName ? props.wrapperClassName : ""
                    }`}
                >
                    <Header key="main-header" {...commonParams} />
                    {props.children}
                </div>
            );
        case 3:
            return <Maintain />;
        case 4:
            return <DynamicRestrictAccess />;
        case 6:
            return (
                <div
                    className={`tlc-container-wrapper-Diamond ${
                        props.wrapperClassName ? props.wrapperClassName : ""
                    }`}
                >
                    <Header
                        key="main-header"
                        {...commonParams}
                        setIsLogin={(val) => {
                            setIsLogin(val);
                        }}
                    />
                    {props.children}
                    <Footer key="main-footer" isLogin={isLogin} isHomePage={props.isHomePage} />
                </div>
            );
        //遊戲頁
        case 7:
            return (
                <div
                    className={`tlc-container-wrapper _1 ${
                        props.wrapperClassName ? props.wrapperClassName : ""
                    }`}
                >
                    <Header
                        key="main-header"
                        {...commonParams}
                        setIsLogin={(val) => {
                            setIsLogin(val);
                        }}
                        setIsWalletSetupComplete={(val) => {
                            setIsWalletSetupComplete(val);
                        }}
                        isGamePage={true}
                    />
                    {isWalletSetupComplete ? props.children : null}
                    <Footer key="main-footer" isLogin={isLogin} isHomePage={props.isHomePage} />
                </div>
            );
        case 8:
            return <div className={`${props.wrapperClassName}`}>
                <div style={{ display: 'none', opacity: 0, width: 0, height: 0 }}>
                    <Header key="main-header" {...commonParams} />
                </div>
                {props.children}
                <Footer key="main-footer" />
            </div>
        default:
            return <div>500 Error!</div>;
    }
}

export default ({
    isHomePage,
    status,
    // 默认需要的内容
    children,
    // 锁定Header状态栏大小
    setLockHeader,
    // 锁定Header状态栏大小所需要的配置参数
    headerHeightLock,
    // 设置登陆状态
    setLoginStatus,
    // 设置Header部分未读标识（小红点）
    setCircleHasUnRead,
    // 设置UserCenter部分未读标识（小红点）
    setUsercenterCircle,
    // 设置UserCenter的钱
    setUserCenterMoney,
    // 设置UserCenter的会员信息
    setUserCenterMemberInfo,
    // 關閉錢包後，開啟遊戲
    setOpenGame,
    // 顶层样式表名称
    wrapperClassName,
    // 自定义Header
    definedHeaderNode,
    title = "Fun88 คาสิโนออนไลน์ชั้นนำ ปลอดภัย เชื่อถือได้สูงสุด ในเอเชีย",
    description = "Fun88 ผู้ให้บริการรับเดิมพันออนไลน์ครบวงจร รองรับการเดิมพันกีฬา คาสิโนสด อีสปอร์ต สล็อต เกม 3D อัพเดตใหม่ล่าสุด หวยออนไลน์ อัตราจ่ายดี มาตรฐานบริการสูง",
    Keywords = "keywords unset",
    getPromotionList,
    seoData,
    linkHref = "",
}) => {
    useEffect(() => {
        setTimeout(() => {
            const HTML_ID = 'seonScriptTag';
            if (!document.getElementById(HTML_ID)) {
                const scriptLink = document.createElement("script");
                scriptLink.id = HTML_ID;
                scriptLink.src = "https://cdn.seondf.com/js/v6/agent.umd.js";
                scriptLink.type = "text/javascript";
                scriptLink.onload = () => {
                    if (window?.seon) {
                        window.seon.init();
                    }
                };
                document.querySelectorAll("head")[0].appendChild(scriptLink);
            }
        });
    }, []);
    return (
        <>
            <Head key="layout-head">
                <title>{seoData?.title ?? title}</title>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
                {/* <meta key="viewport" name="viewport" content="width=device-width, initial-scale=0, user-scalable=yes, maximum-scale=1.0" /> */}
                <meta name="description" content={seoData?.description ?? description} />
                <meta name="Keywords" content={seoData?.keyword ?? Keywords} />
                <meta name="twitter:title" content={seoData?.twitterTitle ?? "twitter title unset"} />
                <meta name="twitter:description" content={seoData?.twitterDescription ?? `${seoStatic.mainDomain} twitter description unset`} />
                <meta name="twitter:card" content={seoData?.twitterCard ?? "twitter card unset"} />
                <meta name="footer" content={seoData?.webFooter ?? "footer unset"} />
                <link rel="canonical" href={seoData?.canonicalHref ?? (linkHref || `https://www.${seoStatic.mainDomain}/th/`)} />
                <link rel="alternate" hrefLang="th-th" href={seoData?.alternateHref ?? `https://www.${seoStatic.mainDomain}/th`} />
                <link
                    rel="shortcut icon"
                    type="image/x-icon"
                    href="/th/img/favicon.ico"
                />
                {/* <script src="https://www.googletagmanager.com/gtag/js?id=UA-45814256-7"></script> */}
                {/* <script src="/th/Geetest/gee-test.js?v=2" /> */}
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link
                    href="https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@200;300;400;500;600;700;900&display=swap"
                    rel="stylesheet"
                />
            </Head>
            <MainComponent
                key="main-component"
                isHomePage={isHomePage}
                status={status}
                setLockHeader={setLockHeader}
                headerHeightLock={headerHeightLock}
                setLoginStatus={setLoginStatus}
                wrapperClassName={wrapperClassName}
                setCircleHasUnRead={setCircleHasUnRead}
                setUsercenterCircle={setUsercenterCircle}
                setUserCenterMoney={setUserCenterMoney}
                setUserCenterMemberInfo={setUserCenterMemberInfo}
                setOpenGame={setOpenGame}
                children={children}
                getPromotionList={getPromotionList}
                definedHeaderNode={definedHeaderNode}
            />
            <SelfExclusionModal
                ModalType={1}
                OpenModalUrl="Home"
                afterCloseModal={() => {
                    Router.push("/");
                }}
            />
            <SmarticoMaintenance />
        </>
    );
};
