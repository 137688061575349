import React from 'react';
import { Row, Col, Button, Icon, Menu, Modal, message, Spin } from 'antd';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import HasLogged from './HasLogged';
import NotLogged from './NotLogged';
import HeaderMenuBar from './HeaderMenuBar';
import UserSign from '@/UserSign';
import { ApiPort } from '$ACTIONS/TLCAPI';
import { Cookie, numberformatter } from '$ACTIONS/util';
import { get, post } from '$ACTIONS/TlcRequest';
import HostConfig from '$ACTIONS/Host.config';
import { getQueryVariable, checkAffQueryString } from '$ACTIONS/helper';
import OpenGame from '@/Games/openGame';
import { getUrlVars } from '$ACTIONS/util';
import ImageWithFallback from '@/ImageWithFallback/imgLocal';
import { connect } from "react-redux";
import { Provider } from "react-redux";
import { store } from "$STORE/store";
import { gameActions } from "$STORE/gameSlice";
import { fetchAffiliateCodeThunk } from "$STORE/thunk/mainSiteConfigThunk";
import LinkHelper from "@/Common/LinkHelper"

function HeaderComponent(props) {
	switch (props.status) {
		case 0:
			return (
				<NotLogged smallHeader={props.smallHeader} AlreadLogin={() => props.this.AlreadLogin()} {...props} />
			);
		case 1:
			return <HasLogged LoginExit={() => props.this.LoginExit()} {...props} key={JSON.stringify(props.status)} />;
		default:
			return null;
	}
}
class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			smallHeader: '',
			allBalance: 0, // 子组件余额记录
			isLogin: 0, // 0 未登录 1 已登录
			showType: -1, // 子组件HasLogged：-1 初始化 0.1 隐藏个人中心 0.2 隐藏余额 1 个人中心简介 2 余额
			messageHasUnRead: false, // 是否有未读消息（交易/个人）
			csvisible: false,
			csurl: '',
			lockHeader: 0, // 锁定Header状态（0：free、大：2、小：1）
			logoutType: '', // 8号商城登出问题
			referFriendLearn: false, // 推荐好友导引步骤
			rafStep: 1, // 推荐好友导引步骤
			isDeposit: false,
			isVerified: false,
			verifyEmail: false, // email link彈窗
			verifyEmailResult: '', // eamil link驗證結果
			EmailResultMessage: '', // eamil link驗證結果
			loading: false,
		};

		this.onUserSign = () => {}; // 初始化登录注册呼出事件
		this.showSimple = () => {}; // 初始化显示个人或者余额下拉
		this.hideSimple = () => {}; // 初始化隐藏个人或者余额下拉
		this.parentShowSimple = this.parentShowSimple.bind(this); // 父组件呼出个人余额下拉
		this.parentHideSimple = this.parentHideSimple.bind(this); // 父组件隐藏个人余额下拉
		this.onHasLoggedEvent = this.onHasLoggedEvent.bind(this); // 获取子组件切换个人余额下拉
		this.ReferrerLinkActivity = this.ReferrerLinkActivity.bind(this);
		this.ReferreeTaskStatus = this.ReferreeTaskStatus.bind(this);
		this.GetThroughoutVerification = this.GetThroughoutVerification.bind(this);
		this.SetdisplayRefereeStatus = this.SetdisplayRefereeStatus.bind(this);
		this.getBrowser = this.getBrowser.bind(this);
		this.referLearnCancel = this.referLearnCancel.bind(this);
		this.resizeThrottle = this.resizeThrottle.bind(this);
		this.toggleHeader = this.toggleHeader.bind(this);
		this.receiveMessage = this.receiveMessage.bind(this);
		this.CheckUrkTokenToLogin = this.CheckUrkTokenToLogin.bind(this);
		this.AlreadLogin = this.AlreadLogin.bind(this);
		this.LoginExit = this.LoginExit.bind(this);
		this.showCS = this.showCS.bind(this);

		this.timer = null;
		this.TLCLive = null;
		this.isClickTLCLive = false;
		this.verifyEmailLink = this.verifyEmailLink.bind(this); // 驗證email link
		global.PopUpLiveChat = this.PopUpLiveChat.bind(this); // 全局化打开客服窗口
		global.goReferFriend = this.goReferFriend.bind(this); // 全局化前往推荐好友
		global.showResultModal = this.showResultModal.bind(this); // 全局化半黑透明提示窗口
		global.showColorResultModal = this.showColorResultModal.bind(this); // 全局化半黑透明提示窗口
		global.GetThroughoutVerification = this.GetThroughoutVerification.bind(this);
	}
	componentDidMount() {
		checkAffQueryString();
		window.addEventListener('scroll', this.resizeThrottle);
		// 传递锁定Header状态的方法出去
		this.props.setLockHeader &&
			this.props.setLockHeader((statusId) => {
				this.setState({
					lockHeader: statusId,
					smallHeader: statusId === 1 ? 'zoom-out' : ''
				});
			});

		// 设置右侧滚动条宽度（避免弹出框因为滚动条抖动的问题）
		this.addStyle(
			`.ant-scrolling-effect .header-warp, .ant-scrolling-effect .fixed-right{right:${this.getScrollbarWidth()}px}.fixed-right{right: 0}`
		);

		// 设置登陆状态
		if (localStorage.getItem('access_token')) {
			this.setState({ isLogin: 1 });
			this.props.setLoginStatus && this.props.setLoginStatus(true);
			this.props.setIsLogin && this.props.setIsLogin(true);
		}

		// 默认获取客服链接，只有在客服链接不同的情况才会替换已记录客服链接
		get(ApiPort.GETLiveChat).then((res) => {
			if (res && res.isSuccess && res.result !== localStorage.getItem('serverUrl')) {
				localStorage.setItem('serverUrl', res.result);
				this.isClickTLCLive && this.showCS(res.result);
				this.isClickTLCLive = false;
			}
		});

		// 邮箱链接进入界面重置密码流程
		const enc = getQueryVariable('enc'),
			actionParam = getQueryVariable('action');
		// 如果action参数为registered则弹出注册窗口
		actionParam === 'registered' && global.goUserSign('2');
		if (enc) {
			window.location.href = `/th/resetpassword?enc=${enc}`;
		}

		// email link的驗證
		if (
			global.location.pathname.indexOf('/th/emailverification') != -1 &&
			enc &&
			actionParam === 'EmailVerification'
		) {
			// Router.push('/');
			setTimeout(() => this.verifyEmailLink(enc), 500);
		}

		// 跳转充值窗口，可以指定支付方式
		const payMethod = getQueryVariable('payMethod');
		if (payMethod) {
			if (!localStorage.getItem('access_token')) {
				global.goUserSign('1');
				return;
			}
			setTimeout(() => {
				const walletKey =
					payMethod === 'default'
						? `wallet:{"type": "deposit"}`
						: `wallet:{"type": "deposit", "currentPayValue": "${payMethod}"}`;
				global.showDialog({ key: walletKey });
			});
		}

		// 推荐好友
		const raf = getQueryVariable('raf');
		if (!!raf) {
			sessionStorage.setItem('queleaReferrerId', raf);
			!localStorage.getItem('UserName') && this.setState({ referFriendLearn: true });
			if (!sessionStorage.getItem('isReferrerFirst')) {
				// this.ReferrerLinkActivity(raf);
				sessionStorage.setItem('isReferrerFirst', 'has');
			}
		}

		// 獲取是否支持第三方cookie
		window.addEventListener('message', this.receiveMessage, false);

		// QRP 登入
		this.CheckUrkTokenToLogin();

		// 獲取 affiliateCode
		this.props.fetchAffiliateCode();
	}

	componentDidUpdate(prevProps, prevState) {
		// 登入狀態改變，刷新資料
		if (prevProps.memberInfo?.memberCode !== this.props.memberInfo?.memberCode) {
			this.props.fetchAffiliateCode();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.resizeThrottle);
	}

	/**
     * @description: QRP登录
     * @return {*}
     */

	CheckUrkTokenToLogin() {
		let vars = getUrlVars();
		if (vars.ref && vars.ref !== '' && vars.token && vars.token !== '') {
			localStorage.setItem('access_token', JSON.stringify('Bearer' + ' ' + vars.token));
			localStorage.setItem('refresh_token', JSON.stringify(vars.ref));
		} else {
			return;
		}

		setTimeout(() => {
			this.AlreadLogin();
			Router.replace(window.location.origin + window.location.pathname);
		}, 1000);

		setTimeout(() => {
			this.RefreshToken(vars.ref);
		}, 10000);
	}

	/**
     * @description: 刷新令牌
     * @param {*}
     * @return {*}
     */
	RefreshToken = (rstoken) => {
		const postData = {
			clientId: 'Fun88.CN.App',
			clientSecret: 'FUNmuittenCN',
			refreshToken: rstoken
		};

		post(ApiPort.RefreshTokenapi, postData)
			.then((res) => {
				if (res?.isSuccess) {
					if (res?.result?.accessToken && res?.result?.refreshToken) {
						localStorage.setItem('access_token', JSON.stringify('bearer ' + res.result.accessToken));
						ApiPort.Token = 'bearer ' + res.result.accessToken;
						localStorage.setItem('refresh_token', JSON.stringify(res.result.refreshToken));
					} else {
						message.error('หมดเวลา กรุณาเข้าสู่ระบบอีกครั้ง', 3); //请重新登录，访问过期！
						setTimeout(() => {
							global.globalExit();
							Router.push('/');
						}, 1500);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	receiveMessage(evt) {
		if (evt.data === 'MM:3PCunsupported') {
			localStorage.setItem('thirdPartyCookie', false);
			// console.log('第三方cookie不支持');
		} else if (evt.data === 'MM:3PCsupported') {
			localStorage.setItem('thirdPartyCookie', true);
			// console.log('支持第三cookie');
		}
	}

	PopUpLiveChat(listingParams = '') {
		// this.setState({
		// 	csvisible: true
		// });
		const serverUrl = localStorage.getItem('serverUrl');
		if (serverUrl) {
			let serverUrlNew = serverUrl + listingParams
			this.showCS(serverUrlNew);
		} else {
			// 记录点击状态，如果当前无客服链接返回，点击后记录，异步响应后打开客服窗口
			this.isClickTLCLive = true;
		}
	}
	ReferreeTaskStatus() {
		const { memberInfo } = this.props;
		if (memberInfo?.displayReferee && localStorage.getItem('OpenReferDone')) {
			return;
		}
		get(ApiPort.ReferreeTaskStatus).then((data) => {
			if (data && data.isSuccess && data.result) {
				console.log('推荐好友状态', data);
				this.setState({
					isDeposit: data.result.isDeposited,
					isVerified: data.result.isContactVerified,
				});
				if (memberInfo?.displayReferee) {
					if (!data.result.isDeposited) {
						this.setState({
							rafStep: 2,
							referFriendLearn: true
						});
					} else {
						if (!data.result.isContactVerified) {
							this.setState({
								rafStep: 3,
								referFriendLearn: true
							});
						} else {
							this.setState({
								rafStep: 4,
								referFriendLearn: true
							});
						}
					}
				} else {
					if (data.result.isActiveCampaign && data.result.isContactVerified) {
						this.GetThroughoutVerification();
					}
				}
			}
		});
	}

	//获取推荐好友的状态
	GetThroughoutVerification() {
		post(ApiPort.ThroughoutVerification)
			.then((data) => {
				let GameReferModal = localStorage.getItem(localStorage.getItem('UserName') + '_GameReferModal');
				if (GameReferModal) {
					return;
				}
				if (data.isSuccess && data.result) {
					this.setState({
						GameOpenModal: true
					});
				} else {
					//重置修改密码后，才弹下面的窗口，否则不弹
					if (data.errors && data.errors[0].errorCode == 'VAL99903') {
						return;
					}
					//弹出不符合资格弹窗
					this.setState({
						Referineligible: true
					});
				}
				this.setState({
					referFriendLearn: false
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	goReferFriend() {
		// if (!localStorage.getItem('access_token')) {
		// 	global.goUserSign('1');
		// 	return;
		// }
		// const hide = message.loading('加载中，请稍后...');
		get(ApiPort.GetQueleaActiveCampaign).then((data) => {
			// hide();
			if (data && data.isSuccess) {
				Router.push('/referrer-activity');
			} else {
				Modal.info({
					className: "blueHeaderModal closable-hide raf-modal",
					width: 400,
					wrapClassName: "blueHeaderModal",
					title: "แจ้งเตือน", //推荐好友活动尚未开始
					icon: null,
					centered: true,
					okText: "ปิด",//离开
					content: (
						<div>
							<center>
								<img src="/th/img/icons/icon-warn.svg" alt="icon-warn" />
								<h3 className="raf-modal-title">
									กิจกรรมแนะนำเพื่อนยังไม่เปิดให้บริการ
								</h3>
								<p>โปรดติดตามกิจกรรมแนะนำเพื่อนและรับโบนัสเร็วๆ นี้</p>
								{/* 敬请期待推荐好友获取彩金活动 */}
							</center>
						</div>
					)
				});
			}
		});
	}
	getBrowser = () => {
		var browser = {
				msie: false,
				firefox: false,
				opera: false,
				safari: false,
				chrome: false,
				netscape: false,
				appname: 'unknown',
				version: 0
			},
			ua = window.navigator.userAgent.toLowerCase();
		if (/(msie|firefox|opera|chrome|netscape)\D+(\d[\d.]*)/.test(ua)) {
			browser[RegExp.$1] = true;
			browser.appname = RegExp.$1;
			browser.version = RegExp.$2;
		} else if (/version\D+(\d[\d.]*).*safari/.test(ua)) {
			// safari
			browser.safari = true;
			browser.appname = 'safari';
			browser.version = RegExp.$2;
		}
		return {
			BrowserType: browser.appname + browser.version,
			BrowserName: browser.appname,
			BrowserVersion: browser.version
		};
	};
	ReferrerLinkActivity(referCode) {
		let data = {
			ReferrerId: referCode,
			LandingPage: window.location.origin,
			BrowserType: this.getBrowser().BrowserType,
			BrowserName: this.getBrowser().BrowserName,
			BrowserVersion: this.getBrowser().BrowserVersion,
			BrowserPlatform: window.navigator.platform,
			UserAgent: window.navigator.userAgent,
			HttpReferer: window.location.origin + '/?raf=' + referCode
		};
		post(ApiPort.ReferrerLinkActivity, data).then((res) => {}).catch((error) => {});
	}
	referLearnCancel() {
		this.setState({ referFriendLearn: false });
		localStorage.setItem('OpenReferDone', localStorage.getItem('UserName'));
		this.SetdisplayRefereeStatus();
	}
	goUserCenter(key) {
		if (~window.location.pathname.indexOf(`/th/me/${key}`) || ~window.location.pathname.indexOf(`/th/${key}`)) {
			console.log("click same tab");
			return;
		} else {
			if (key === "betting-record" || key === "notification" || key === "transaction-record") {
				Router.push(`/${key}`);
			} else {
				Router.push(`/me/${key}`);
			}
		}
	}
	// 已登录组件事件传递
	onHasLoggedEvent(showEvent, hideEvent) {
		this.showSimple = showEvent;
		this.hideSimple = hideEvent;
	}
	parentShowSimple(type) {
		this.setState({ showType: type });
		this.showSimple(type, 'top');
	}
	parentHideSimple(type) {
		this.setState({ showType: 0 });
		this.hideSimple(type);
	}
	resizeThrottle() {
		if (this.timer) {
			clearTimeout(this.timer);
		}
		this.timer = setTimeout(this.toggleHeader, 30);
	}
	toggleHeader() {
		if (this.state.lockHeader) {
			return;
		}
		const h = window,
			e = document.body,
			a = document.documentElement;
		const offsetY = Math.max(0, h.pageYOffset || a.scrollTop || e.scrollTop || 0) - (a.clientTop || 0);
		this.setState({ smallHeader: offsetY >= 10 ? 'zoom-out' : '' });
	}
	getScrollbarWidth() {
		// Creating invisible container
		const outer = document.createElement('div');
		outer.style.visibility = 'hidden';
		outer.style.overflow = 'scroll'; // forcing scrollbar to appear
		outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
		document.body.appendChild(outer);

		// Creating inner element and placing it in the container
		const inner = document.createElement('div');
		outer.appendChild(inner);

		// Calculating difference between container's full width and the child width
		const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

		// Removing temporary elements from the DOM
		outer.parentNode.removeChild(outer);

		return scrollbarWidth;
	}
	addStyle(newStyle) {
		const overflowDistance = 'overflow_distance';
		var styleElement = document.getElementById(overflowDistance);

		if (!styleElement) {
			styleElement = document.createElement('style');
			styleElement.type = 'text/css';
			styleElement.id = overflowDistance;
			document.getElementsByTagName('head')[0].appendChild(styleElement);
			styleElement.appendChild(document.createTextNode(newStyle));
		}
	}

	showResultModal(value, type = true, zIndex = 1501, key, name = '') {
		// 半透黑彈窗
		const opacityMaskDialog = Modal.info({
			title: ``,
			icon: <div />,
			zIndex: zIndex,
			centered: true,
			mask: true,
			footer: null,
			content: (
				<div style={{ textAlign: 'center' }}>
					<img src={`/th/img/icons/${type ? 'icon-success' : 'icon-error'}.png`} width="48px" />
					<p
						style={{
							marginTop: 25,
							marginBottom: 0,
							// padding: key == 'otp' ? '0' : '0 14px'
						}}
					>
						{value}
					</p>
				</div>
			),
			className: `showResultModal opacity ${name}`
		});
		setTimeout(() => {
			opacityMaskDialog.destroy();
		}, 3000);
	}

	showColorResultModal(value, type, zIndex = 1501, key, name = '') {
		// 半透黑彈窗
		const opacityMaskDialog = Modal.info({
			title: ``,
			zIndex: zIndex,
			centered: true,
			mask: false,
			content: (
				<div>
					<img
						width="20"
						style={{ marginRight: '0.3rem' }}
						src={`/th/img/icons/${type ? 'icon-checked' : 'failed-filled'}.png`}
					/>
					<div
						style={{
							marginTop: '0.2rem',
							color: type ? '#0CCC3C' : '#cc0c0c'
						}}
					>
						{value}
					</div>
				</div>
			),
			className: `showInfoColorModal ${type} ${name}`
		});
		setTimeout(() => {
			opacityMaskDialog.destroy();
		}, 3000);
	}

	AlreadLogin = () => {
		this.setState({ isLogin: 1, logoutType: '' });
		this.props.setLoginStatus && this.props.setLoginStatus(true);
		this.props.setIsLogin && this.props.setIsLogin(true);
	};

	LoginExit = () => {
		this.setState({ isLogin: 0 });
		this.props.setLoginStatus && this.props.setLoginStatus(false);
		this.props.setIsLogin && this.props.setIsLogin(false);
	};

	showCS(url) {
		// let _this = this;
		// if (this.state.csurl != '') {
		if (!this.TLCLive || (this.TLCLive && this.TLCLive.closed)) {
			this.TLCLive = window.open(
				'about:blank',
				'chat',
				'toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=540, height=650'
			);
			this.TLCLive.document.title = 'ฝ่ายบริการลูกค้า'; //乐天堂在线客服
			this.TLCLive.location.href = url;
			this.TLCLive.focus();
		} else {
			this.TLCLive.focus();
		}
		// _this.setState({
		// 	csvisible: false
		// });
		// } else {
		// 	message.error('哎呀！出现错误请稍后重试！');
		// }
	}

	cschange() {
		this.setState({
			csvisible: false
		});
	}

	verifyEmailLink(enc) {
		this.setState({ loading: true });
		let data = {
			EncyptedText: enc
		};
		this.setState({ verifyEmail: true });
		post(ApiPort.POSTEmailVerifyLink, data)
			.then((res) => {
				if (res != '') {
					res.result &&
						this.setState({
							loading: false,
							verifyEmailResult: res.isSuccess,
							EmailResultMessage: res.result.message
						});
				}
			})
			.catch((error) => {});
	}

	/**
     * @description: 写入打开Referee弹窗的状态
     * @return {*}
     */

	SetdisplayRefereeStatus() {
		if (this.state.rafStep == 4) {
			localStorage.setItem(localStorage.getItem('UserName') + '_displayReferee', true);
		}
	}

	SetGameReferStatus() {
		localStorage.setItem(localStorage.getItem('UserName') + '_GameReferModal', 'TRUE');
	}

	render() {
		const { smallHeader, showType, Referineligible, GameOpenModal } = this.state;
		const { definedHeaderNode, smarticoVisible } = this.props;
		return (
			<React.Fragment>
				{/* 自定义Header并不损失Header处引入的功能 */}
				{!definedHeaderNode ? null : definedHeaderNode}
				<div
					style={{
						display: !definedHeaderNode ? 'block' : 'none',
						color: '#fff'
					}}
				>
					<div className={`header-warp next-header-bar-wrap common-distance-wrap ${smallHeader}`}>
						<div className="common-distance">
							<Row className="header-Row">
								<Col span={24}>
									<Row className="next-menu-bar">
										<Col span={7}>
										  <LinkHelper 
										   href='/'
										  >
											<div className="logo-wrap">
												<ImageWithFallback
													src="/th/img/home/logo@2x.png"
													width={145}
													height={32}
													alt="FUN88"
													fallbackSrc="/th/img/logo.svg"
												/>
											</div>
											</LinkHelper>
											
										</Col>
										<Col span={17}>
										    <Menu
												mode="horizontal"
											>
												<Menu.Item key="1">
													{this.state.isLogin ? (
														<Menu
															mode="horizontal"
															className="zoom-in-section"
															onClick={(item) => {
																switch (item.key) {
																	case '3':
																		global.showDialog({
																			key: 'wallet:{"type": "deposit"}'
																		});
																		Pushgtagdata('Home', 'Go to Deposit', 'Home_C_Deposit');
																		break;
																	case '4':
																		global.showDialog({
																			key: 'wallet:{"type": "withdraw"}'
																		});
																		Pushgtagdata('Home', 'Go to Withdrawal', 'Home_C_Withdrawal');
																		break;
																	case '5':
																		global.showDialog({
																			key: 'wallet:{"type": "transfer"}'
																		});
																		Pushgtagdata('Home', 'Go to Transfer', 'Home_C_Transfer');
																		break;
																	default:
																		break;
																}
															}}
														>
															<Menu.Item key="1" className="clear-padding">
																<div
																	className={`user-center-btn${showType === 1
																		? ' active'
																		: ''}`}
																	onMouseEnter={() => this.parentShowSimple(1)}
																	onMouseLeave={() => this.parentHideSimple(0.1)}
																>
																	<span>ข้อมูลบัญชี</span>
																	{/* 个人中心 */}
																	<i
																		className={`tlc-sprite user-message ${this.state
																			.messageHasUnRead
																			? 'remind-circle'
																			: ''}`}
																	/>
																	<Icon type="caret-down" />
																</div>
															</Menu.Item>
															<Menu.Item key="2" className="clear-padding">
																<div
																	className={`wallet-center-btn${showType === 2
																		? ' active'
																		: ''}`}
																	onMouseEnter={() => this.parentShowSimple(2)}
																	onMouseLeave={() => this.parentHideSimple(0.2)}
																>
																	<span>฿ </span>
																	<span>{numberformatter(this.state.allBalance)}</span>
																	<Icon type="caret-down" />
																</div>
															</Menu.Item>
															<Menu.Item key="3" className="header-btn btnYellow">
																ฝากเงิน
																{/* 存款 */}
															</Menu.Item>
															<Menu.Item key="5" className="header-btn btnBlue">
																โอนเงิน
																{/* 转账 */}
															</Menu.Item>
															<Menu.Item key="4" className="header-btn btnPurple">
																ถอนเงิน
																{/* 提款 */}
															</Menu.Item>
														</Menu>
													) : (
														<Menu
															mode="horizontal"
															className="zoom-in-section text-right"
															onClick={(item) => {
																switch (item.key) {
																	case '1':
																	case '2':
																		this.onUserSign(item.key);
																		break;
																	default:
																		break;
																}
															}}
														>
															<Menu.Item key="1" className="header-btn btn-orange login-btn">
																เข้าสู่ระบบ {/* 登录 */}
															</Menu.Item>
															<Menu.Item
																key="2"
																className="header-btn btn-blue btn-header-register register-btn"
															>
																ลงทะเบียน {/* 注册 */}
															</Menu.Item>
														</Menu>
													)}
												</Menu.Item>
												{smarticoVisible && (
													<Menu.Item
														key="2"
														className="rewardsCentre-btn"
														onClick={() => {
															Pushgtagdata(
																"Home_TopNav",
																"Go to RewardsCentre",
																"Home_TopNav_C_RewardsCentre"
															);
															if (!localStorage.getItem('access_token')) {
																this.props.setCacheGame({ type: "smartico" });
																global.goUserSign('1');
																return;
															}
														}}
													>
														<LinkHelper
														  href="/rewards-centre"
														  blockHrefRedirection 
														>
													    	<div className="rewardsCentre" />
														</LinkHelper>
													</Menu.Item>
												)}
												{/* 在线客服按钮 */}
												<Menu.Item
													key="3"
													className="live-server-btn"
													onClick={() => {
														Pushgtagdata("Home", "Contact CS", "Home_C_CS");
														// global.PopUpLiveChat();
														window.open('/th/CustomerService/')
													}}
												>
													<LinkHelper 
													   href='/CustomerService/' 
													   blockHrefRedirection 
													>
													  <div className="tlc-sprite live-service" />
													</LinkHelper>
												</Menu.Item>
											</Menu>
										</Col>
									</Row>
									<HeaderMenuBar
										getPromotionList={this.props.getPromotionList}
									/>
								</Col>
							</Row>
						</div>
					</div>

					<HeaderComponent
						ReferreeTaskStatus={this.ReferreeTaskStatus}
						goUserCenter={this.goUserCenter}
						onChangeShowType={(v) => {
							this.setState({ showType: v });
						}}
						onHasLoggedEvent={this.onHasLoggedEvent}
						setUserAllBalance={(v) => {
							this.setState({ allBalance: v });
						}}
						setUsercenterCircle={this.props.setUsercenterCircle}
						setMessageHasUnRead={(v) => {
							this.setState({ messageHasUnRead: v });
						}}
						setCircleHasUnRead={this.props.setCircleHasUnRead}
						setUserCenterMoney={this.props.setUserCenterMoney}
						setUserCenterMemberInfo={this.props.setUserCenterMemberInfo}
						setOpenGame={this.props.setOpenGame}
						setIsWalletSetupComplete={this.props.setIsWalletSetupComplete}
						isGamePage={this.props.isGamePage}
						smallHeader={this.state.smallHeader}
						status={this.state.isLogin}
						exitShop={(v) => {
							this.setState({ logoutType: v });
						}}
						this={this}
						key={this.state.isLogin + 'status'}
						GetThroughoutVerification={() => {
							this.GetThroughoutVerification();
						}}
					/>
				</div>

				<UserSign
					AlreadLogin={() => this.AlreadLogin()}
					onUserSign={(handleEvent) => (this.onUserSign = handleEvent)}
				/>

				<Modal
					title="在线客服"
					closeIcon={<Icon type="close" style={{ fontSize: '18px' }} />}
					className="modal-pubilc"
					visible={this.state.csvisible}
					onOk={() => this.cschange()}
					onCancel={() => this.cschange()}
					width={450}
					footer={null}
					zIndex={9999}
				>
					<p> 您需要帮助吗？请选择想要的在线客服服务帮您答疑解惑～</p>
					<div className="csbox">
						<div
							className="l"
							onClick={() => {
								this.showCS();
							}}
						>
							<img src="/th/img/cs/live.png" />
							<p>在线客服专员</p>
						</div>
						<div
							className="r"
							onClick={() => {
								this.showCS();
							}}
						>
							<img src="/th/img/cs/ai.png" />
							<p>在线客服专员</p>
						</div>
					</div>
				</Modal>
				<Modal
					title={"ลงทะเบียนรับโบนัสได้ง่ายๆ"}
					visible={this.state.referFriendLearn}
					className="raf-step-modal"
					footer={false}
					width={316}
					maskClosable={false}
					onCancel={() => {
						this.referLearnCancel();
					}}
					closable={false}
				>
					{/* <h3 className="refer-friend-title center">รับโบนัส 100 บาทง่ายๆ</h3> */}
					<ul className="invite-list-step has-login learn">
						<li>
							<div className="invite-step-content">
								<h4>ขั้นตอนที่ 1</h4>
								{/* 第一步 */}
								<p>สมัครเป็นสมาชิก Fun88</p>
								{/* 点击 “立即注册” 成为我们的会员 */}
								<Button
									type="danger"
									onClick={() => {
										this.referLearnCancel();
										global.goUserSign('2');
									}}
									disabled={this.state.rafStep !== 1}
								>
									สมัครสมาชิก
									{/* 立即注册 */}
								</Button>
							</div>
							{this.state.rafStep !== 1 ? (
								<Icon type="check-circle" theme="filled" className="success" />
							) : (
								<Icon type="check-circle" />
							)}
						</li>
						<li>
							<div className="invite-step-content">
								<h4>ขั้นตอนที่ 2</h4>
								{/* 第二步 */}
								<p>ยืนยันชื่อ - นามสกุลและเบอร์โทร</p>
								{/* 进行實名和手机的验证 */}
								<Button
									type="danger"
									onClick={() => {
										this.referLearnCancel();
										this.SetdisplayRefereeStatus();
										this.goUserCenter('account-info');
									}}
									disabled={this.state.isVerified || this.state.rafStep === 1}
								>
									ยืนยันตอนนี้
									{/* 立即验证 */}
								</Button>
							</div>
							{this.state.isVerified && this.state.rafStep !== 1 ? (
								<Icon type="check-circle" theme="filled" className="success" />
							) : (
								<Icon type="check-circle" />
							)}
						</li>
						<li>
							<div className="invite-step-content">
								<h4>ขั้นตอนที่ 3</h4>
								{/* 第三步 */}
								<p>ฝากเงินครั้งแรก</p>
								{/* 进入存款页面完成首存 */}
								<Button
									type="danger"
									onClick={() => {
										this.referLearnCancel();
										global.showDialog({
											key: 'wallet:{"type": "deposit"}'
										});
										this.SetdisplayRefereeStatus();
									}}
									disabled={this.state.isDeposit || this.state.rafStep === 1}
								>
									ฝากเงิน
									{/* 立即充值 */}
								</Button>
							</div>
							{this.state.isDeposit && this.state.rafStep !== 1 ? (
								<Icon type="check-circle" theme="filled" className="success" />
							) : (
								<Icon type="check-circle" />
							)}
						</li>
					</ul>
					<Button block type="link" size="small" onClick={this.referLearnCancel}>
						ปิด
						{/* 关闭 */}
					</Button>
				</Modal>

				<Modal
					title={'แจ้งเตือน'} //温馨提示
					className="raf-modal"
					visible={Referineligible}
					closable={false}
					onCancel={() => {
						this.setState({
							Referineligible: false
						});
						this.SetGameReferStatus();
					}}
					centered={true}
					width={400}
					footer={null}
					zIndex={1500}
				>
					<Row>
						<Col span={24}>
							<img src="/th/img/icons/icon-warn.svg" alt="icon-warn" />
							<h3 className="raf-modal-title">ไม่เข้าเงื่อนไข</h3>
							{/* 不符合资格 */}
							<p>
								ขออภัย ขณะนี้บัญชีของคุณยังไม่สามารถเข้าร่วม<br />
								โปรแกรมแนะนำเพื่อนได้ ตรวจสอบโปรโมอื่นๆ<br />
								หรือติดต่อฝ่ายบริการลูกค้า
							</p>
							{/* 抱歉，目前您的账户不符合推荐好友活动的资格。请尝试申请其他优惠，或联系在线客服咨询。 */}
						</Col>
					</Row>
					<div className={`btn-wrap otp-btn-wrap`}>
						<center>
							<Button
								className="raf-btn"
								type="primary"
								size="large"
								ghost
								onClick={() => {
									this.setState({
										Referineligible: false
									});
									this.SetGameReferStatus();
								}}
							>
								ปิด
								{/* 关闭 */}
							</Button>
							<Button
								className="raf-btn"
								type="primary"
								size="large"
								onClick={() => global.PopUpLiveChat()}
							>
								ติดต่อฝ่ายบริการ
								{/* 联系在线客服 */}
							</Button>
						</center>
					</div>
				</Modal>

				<Modal
					title={'แจ้งเตือน'} //温馨提示
					visible={GameOpenModal}
					className="ReferModal raf-modal"
					maskClosable={false}
					width={400}
					footer={null}
					onCancel={() => {
						this.setState({
							GameOpenModal: false
						});
						this.SetGameReferStatus();
					}}
				>
					<Provider store={store}>
						<React.Fragment>
							<center>
								<img src="/th/img/icons/icon-reject.svg" />
								<h3 style={{ paddingTop: '10px', fontSize: "18px" }}>
									<b>สำเร็จ</b>
									{/* 恭喜您 ！ */}
								</h3>
							</center>
							<div className="note">
								โบนัสจะปรับเข้าสู่บัญชีหลักภายใน 24 ชั่วโมง หากเข้าเงื่อนไข
								{/* 完成推荐好友活动条件，彩金将在24小时内到帐。 */}
							</div>
							<h4 className="RecommendedGames">แนะนำเกม</h4>
							{/* 推荐游戏 */}
							<div className="GameReferImg">
                                <div
                                    onClick={() => {
                                        this.SetGameReferStatus();
                                    }}
                                >
                                    <OpenGame
                                        itemsData={{
                                            type: "RAFGame",
                                            gameName: "AMB",
                                            provider: "AMB",
                                            gameId: 0,
                                            imageUrl: "//th/img/game/AMB.jpg",
                                            gameCatCode: "Slot",
                                            OpenGamesListPage: true,
                                        }}
                                        hideTag={true}
                                    />
                                </div>
                                <div
                                    onClick={() => {
                                        this.SetGameReferStatus();
                                    }}
                                >
                                    <OpenGame
                                        itemsData={{
                                            type: "RAFGame",
                                            gameName: "SPX",
                                            provider: "SPX",
                                            gameId: 0,
                                            imageUrl: "//th/img/game/SPX.jpg",
                                            gameCatCode: "Slot",
                                            OpenGamesListPage: true,
                                        }}
                                        hideTag={true}
                                    />
                                </div>
                                <div
                                    onClick={() => {
                                        this.SetGameReferStatus();
                                    }}
                                >
                                    <OpenGame
                                        itemsData={{
                                            type: "RAFGame",
                                            gameName: "WEC",
                                            provider: "WEC",
                                            gameId: 0,
                                            imageUrl: "//th/img/game/WEC.jpg",
                                            gameCatCode: "LiveCasino",
                                            OpenGamesListPage: true,
                                        }}
                                        hideTag={true}
                                    />
                                </div>
                            </div>
						</React.Fragment>
					</Provider>
				</Modal>

				{/* email link 驗證彈窗*/}
				<Modal
					title={!this.state.loading ? this.state.verifyEmailResult ? '成功验证邮箱' : '验证邮箱失败' : ''}
					closeIcon={<Icon type="close" style={{ fontSize: '18px' }} />}
					className="modal-pubilc"
					// visible={true}
					visible={this.state.verifyEmail}
					onOk={this.handleEvent}
					onCancel={() => this.setState({ verifyEmail: false })}
					width={400}
					footer={null}
				>
					<Spin spinning={this.state.loading}>
						{this.verifyEmailResult != '' && (
							<React.Fragment>
								<div
									style={{
										padding: '30px 40px 47px 40px',
										margin: '0 auto'
									}}
									dangerouslySetInnerHTML={{
										__html: this.state.EmailResultMessage
									}}
								/>
								<Button
									size="large"
									type="primary"
									block
									onClick={() => {
										this.setState({ verifyEmail: false });
										if (this.state.verifyEmailResult) {
											global.goUserSign('1');
										}
									}}
								>
									{this.state.verifyEmailResult ? '立即登录' : '确认'}
								</Button>
							</React.Fragment>
						)}
					</Spin>
				</Modal>
				{this.state.isLogin ? (
					<iframe src="https://www.zbbc88.com/cms/3rdCookie/start.html" style={{ display: 'none' }} />
				) : null}
				{/* 8号商城登出Iframe */}
				{this.state.logoutType ? <iframe src={this.state.logoutType} style={{ display: 'none' }} /> : null}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
    memberInfo: state.userCenter.memberInfo,
	smarticoVisible: state.game.smarticoVisible,
});

const mapDispatchToProps = function (dispatch) {
	return {
		setCacheGame: (data) => {
            dispatch(gameActions.setCacheGame(data));
        },
		fetchAffiliateCode: () => {
            dispatch(fetchAffiliateCodeThunk());
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
