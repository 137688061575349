/*
 * @Author: Alan
 * @Date: 2023-02-12 20:37:42
 * @LastEditors: Alan
 * @LastEditTime: 2023-07-07 09:30:43
 * @Description: 启动游戏组件（所有关于启动游戏皆用此组件）
 * @FilePath: /F1-M2-WEB-Code/components/Games/openGame.js
 */
import React from "react";
import { Spin, message, Modal } from "antd";
import Router from "next/router";
import { ApiPort } from "$ACTIONS/TLCAPI";
import { get } from "$ACTIONS/TlcRequest";
import Tag from "@/Games/Tag";
import { showSpin, hideSpin } from "../../store/spinSlice";
import { connect } from "react-redux";
import Image from "next/image";
import ImageWithFallback from "@/ImageWithFallback/";
import classNames from "classnames";
import { isGuest, isAllowGuestOpenGame, isAllowGuestOpenGameListPage } from "$ACTIONS/helper";
import { mapGameProduct, convertCodeToName } from "$DATA/gameUtils";
import { gameActions } from "$STORE/gameSlice";
import { lobbyWithCategory } from "$ACTIONS/constantsData";
import { setAviatorGameId } from "$STORE/thunk/gameThunk";
import LinkHelper from "@/Common/LinkHelper"

class openGame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentList: [], // 当前显示的数据
        };
        if (props.OnRef) {
            props.OnRef(this);
        }
    }
    componentDidMount() {}

    /**
     * @description: 启动游戏
     * @param {*} items 游戏参数
     * @return {*}
     */
    openGame(items) {
        console.log("当前打开的游戏", items);
        let IS_GUEST = isGuest();
        let IS_LOGINNED_MEMBER = !IS_GUEST;
        let IS_ALLOW_GUEST_OPEN_GAMELIST = isAllowGuestOpenGameListPage(items.gameCatCode, items.provider);
        let IS_ALLOW_GUEST_OPEN_IFRAME_OR_NEW_WINDOW_GAME = isAllowGuestOpenGame(items.gameCatCode, items.provider);
        //如果未登录，弹出登录窗口
        if (
            IS_GUEST &&
            !(IS_ALLOW_GUEST_OPEN_GAMELIST && items.OpenGamesListPage) &&
            !IS_ALLOW_GUEST_OPEN_IFRAME_OR_NEW_WINDOW_GAME
        ) {
            if (items.provider === "FCG") {
                const cacheGameInfo = {
                    type: "openGame",
                    data: items,
                };
                this.props.setCacheGame(cacheGameInfo);
            }
            global.goUserSign("1");
            return;
        }

        const { memberInfo } = this.props;
        //检测账户是否封锁
        if (IS_LOGINNED_MEMBER && memberInfo?.isGameLock) {
            message.error("บัญชีของคุณถูกล็อค โปรดติดต่อฝ่ายบริการลูกค้า"); //您的账户已被锁，请联系在线客服。
            return;
        }

        //跳转游戏列表页面
        if (
            (items.provider == "VTG" && items.Type == "GameInfoPage") ||
            (items.provider == "VTG" && items.Type == "HeaderMenu") ||
            items.OpenGamesListPage
        ) {
            // 查找對應的 providerName
            const providerName = convertCodeToName(items.gameCatCode, items.provider);
            Router.push({
                pathname: "/" + mapGameProduct.find(v=>v.productCode === items.gameCatCode).name + "/" + providerName,
            });
            return;
        }

        if (
            typeof SelfExclusionsisDisabled != "undefined" &&
            SelfExclusionsisDisabled
        ) {
            CheckSelfExclusions(true);
            return;
        }

        this.actionGame(items);
    }

    //不支持ifame
    IsNoIframe(provider) {
        let thirdPartyCookie = JSON.parse(
            localStorage.getItem("thirdPartyCookie")
        );
        if (
            (thirdPartyCookie == false &&
                isCookieNoOpenGame.find((v) => v == provider)) ||
            isWindowOpenGame.find((v) => v == provider)
        ) {
            return true;
        }
        return false;
    }

    actionGame(items) {
        if (!items) {
            console.error("Invalid items");
            return;
        }
        const { provider, launchGameCode, gameCatCode, gameId } = items;

        // 檢查是否是 AVIATOR 遊戲
        if (provider == "AVIATOR" || launchGameCode == "aviator") {
            //启动飞行游戏
            this.OpenAviator();
            return;
        }

        // 查找對應的 providerName
        const providerName = convertCodeToName(items.gameCatCode, items.provider);
        const gamePath = mapGameProduct.find(v => v.productCode === gameCatCode)?.name;

        // 驗證 gamePath 是否存在
        if (!gamePath) {
            console.error(`無效的 gameCatCode: ${gameCatCode}`);
            return;
        }

        // 判斷是否需要帶 query: gameId 的網址
        const route = {
            pathname: `/${gamePath}/${providerName}`,
            ...(lobbyWithCategory.some(cat => cat.toLowerCase() === gameCatCode.toLowerCase()) && { query: { gameid: gameId } })
        };

        // 直接iframe启动游戏
        Router.push(route);
    }

    /**
     * @description: 开启飞行游戏
     * @param {boolean} isOnlyGetId: 添加此 FLag 以用於僅獲取 gameId 以放入 <a>
     * @return {*}
     */

    OpenAviator(isOnlyGetId=false) {
        const { showSpin, hideSpin } = this.props;
        showSpin([true, "กำลังโหลด..."]); //加载中...
        get(
            ApiPort.CmsGames +
                `?gameType=InstantGames&gameSortingType=Default&provider=SPR&api-version=2.0&platform=web`
        ).then((res) => {
            hideSpin();
            if (res.result && res.result.gameDetails?.length != 0) {
                let item = res.result.gameDetails.find(
                    (ele) => ele.launchGameCode === "aviator"
                );
                if(isOnlyGetId){
                    const { setAviatorGameIdForHref } = this.props;
                    return setAviatorGameIdForHref(item.gameId);
                }
                if (item) {
                    // 查找對應的 providerName
                    const providerName = convertCodeToName("InstantGames", item.provider);
                    Router.push(
                        {
                            pathname: `/${mapGameProduct.find(v=>v.productCode === "InstantGames").name}/${providerName}`,
                            query: {
                                gameid: item.gameId,
                            },
                        }
                    );
                } else {
                    Modal.info({
                        className: "confirm-modal-of-public game-popup",
                        icon: <div />,
                        okText: "ตกลง", //知道了
                        title: "แจ้งเตือน", //温馨提醒
                        width: 400,
                        centered: true,
                        content:
                            res.message ||
                            "เกมที่คุณเปิดอยู่ระหว่างปิดปรับปรุง กรุณาลองอีกครั้งภายหลัง หากมีข้อมูลต้องการสอบถาม กรุณาติดต่อฝ่ายบริการลูกค้า", //您所打开的游戏正在维护中，请稍后再尝试进入。若您有任何疑问，请联系我们的在线客服。
                    });
                }
            }
        });
    }

    isAviatorGame(provider, launchGameCode) {
        return provider === "AVIATOR" || launchGameCode === "aviator"; 
    }

    isGameListPage(OpenGamesListPage, Type, provider) {
        return OpenGamesListPage || ((Type === "GameInfoPage" || Type === "HeaderMenu") && provider === "VTG");
    } 

    isLobbyWithCategory(gameCatCode) {
        return lobbyWithCategory.some(cat => cat.toLowerCase() === gameCatCode.toLowerCase()); 
    }

    generateGameLink(items) {
        if (!items) {
            return { href: "#", as: "#" }; // 默认空路由
        }

        const { provider, launchGameCode, gameCatCode, gameId, OpenGamesListPage, Type } = items;
        const providerName = convertCodeToName(gameCatCode, provider);
        const gamePath = mapGameProduct.find(v => v.productCode === gameCatCode)?.name;

        // 检查游戏分类路径是否存在
        if (!gamePath) {
            console.error(`Invalid gameCatCode: ${gameCatCode}`);   
            return { href: "#", as: "#" };
        }

        // AVIATOR 游戏
        if (this.isAviatorGame(provider, launchGameCode)) {
            const { aviatorGameId } = this.props;
            const basePath = "/arcade-games/spribe/";
            if(!aviatorGameId){
                 this.OpenAviator(true);
            }
            return { href: `${basePath}/?gameid=${aviatorGameId}`}; 
        }

        // 游戏列表页面
        if (this.isGameListPage(OpenGamesListPage, Type, provider)) {
            return { 
                href: `/${gamePath}/${providerName}`, 
                as: `/${gamePath}/${providerName}`
            };
        }

        // 判斷是否需要帶 query: gameId 的網址
        if (this.isLobbyWithCategory(gameCatCode)) {
            return { 
                href: `/${gamePath}/${providerName}?gameid=${gameId}`, 
                as: `/${gamePath}/${providerName}?gameid=${gameId}` 
            };
        }

        // default
        return {
            href: `/${gamePath}/${providerName}`,
            as: `/${gamePath}/${providerName}`
        };
    };

    render() {
        const { itemsData, customHtml, hideTag, QuickStartGame, className } =
            this.props;

        const gameLink = this.generateGameLink(itemsData);

        const getImageDimensions = (gameCatCode, type) => {
            if (type && type === "RAFGame") {
                return { width: 340, height: 80 };
            } else {
                return { width: 349, height: 216 };
            }
        };

        return (
            <React.Fragment>
                {!QuickStartGame && (
                    <React.Fragment>
                        {/* 头部导航栏启动 */}
                        <LinkHelper
                          href={gameLink.href}
                          as={gameLink.as}
                          blockHrefRedirection 
                        >
                        {itemsData &&
                            itemsData.Type &&
                            itemsData.Type == "HeaderMenu" && (
                                <a
                                    onClick={() => {
                                        this.openGame(itemsData);
                                    }}
                                    className={classNames({
                                        isNew: itemsData.isNew,
                                        isHot: itemsData.isHot,
                                    })}
                                >
                                    <div
                                        className={classNames({
                                            ["list-content"]: true,
                                            ["header-list-content"]: true,
                                        })}
                                    >
                                        <ImageWithFallback
                                            src={itemsData.imageUrl}
                                            width={100}
                                            height={100}
                                            alt={itemsData.provider}
                                            fallbackSrc="/th/img/logo.svg"
                                        />
                                        <p>{itemsData.gameName}</p>
                                    </div>
                                </a>
                            )}
                        {/* 普通游戏图片启动 */}
                        {itemsData && !itemsData.Type && !customHtml && (
                            <div
                                className="openGame"
                                onClick={() => {
                                    this.openGame(itemsData);
                                }}
                            >
                                {!hideTag && (
                                    <Tag
                                        provider={itemsData.provider}
                                        providerFlag={itemsData.providerFlag || itemsData.provider}
                                        gameCatCode={itemsData.gameCatCode}
                                    />
                                )}
                                <ImageWithFallback
                                    src={itemsData.imageUrl || "/th/img/logo.svg"}
                                    width={
                                        getImageDimensions(
                                            itemsData.gameCatCode, itemsData.type
                                        ).width
                                    }
                                    height={
                                        getImageDimensions(
                                            itemsData.gameCatCode, itemsData.type
                                        ).height
                                    }
                                    alt={itemsData.gameName}
                                    fallbackSrc="/th/img/logo.svg"
                                />

                                <div className="GameName">
                                    {itemsData.gameName}
                                </div>
                            </div>
                        )}
                        </LinkHelper>
                        {/* 自定义显示 */}
                        {customHtml && customHtml(this)}
                  
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    memberInfo: state.userCenter.memberInfo,
    aviatorGameId: state.game.aviatorGameId,
});

const mapDispatchToProps = (dispatch) => ({
    showSpin,
    hideSpin,
    setCacheGame: (data) => gameActions.setCacheGame(data),
    setAviatorGameIdForHref: (gameId) => dispatch(setAviatorGameId(gameId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(openGame);
